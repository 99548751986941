import useTranslation from 'next-translate/useTranslation'
import Image from 'next/legacy/image'
import Link from 'next/link'
import React, { FC, memo } from 'react'
import { FlexboxGrid, Panel } from 'rsuite'

import useUrls from '../../../services/useUrls'
import { getLargePreview, imageLoader } from '../../../utils/images'
import { ProductMedia } from '../../../utils/types/Product'
import FallBackCategoryImg from '../../Graphics/FallBackCategoryImg'

import styles from '../../../styles/LandingPage/CategoryCardLandingPage.module.less'

interface CategoryCardLandingProps {
  categoryName: string
  value: string
  media?: ProductMedia[]
}

const CategoryCardLanding: FC<CategoryCardLandingProps> = ({ categoryName, value, media }) => {
  const { urlT } = useUrls()
  const { t } = useTranslation('catalog')

  const categoryImgAlt = t('meta:categoryThumbnailAltDescription', { categoryName })

  return (
    <div data-testid="category-card" className={styles['category-card']}>
      <Link href={urlT(`/products/category/${value}`)} shallow>
        <Panel bodyFill>
          <FlexboxGrid.Item className={styles.body}>
            <FlexboxGrid justify="center" className={`relative ${styles['image-container']}`}>
              {media?.[0]['media.url'] ? (
                <div className={styles['image-container-height']}>
                  <Image
                    priority
                    loader={imageLoader}
                    src={getLargePreview(media[0])}
                    alt={categoryImgAlt}
                    layout="fill"
                  />
                </div>
              ) : (
                <FallBackCategoryImg />
              )}
            </FlexboxGrid>
            <div className={styles['category-name']}>{t(categoryName)}</div>
          </FlexboxGrid.Item>
        </Panel>
      </Link>
    </div>
  )
}
export default memo(CategoryCardLanding)
