import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import React, { FC, memo } from 'react'
import { FlexboxGrid, Panel } from 'rsuite'

import useUrls from '../../../services/useUrls'
import { getSmallPreview } from '../../../utils/images'
import { ProductMedia } from '../../../utils/types/Product'
import Avatar from '../../Avatar/Avatar'

import styles from '../../../styles/LandingPage/SupplierCardLandingPage.module.less'

interface SupplierCardProps {
  supplierName: string | undefined
  value: string
  media?: ProductMedia[]
  productsCount: number | undefined
}

const SupplierCard: FC<SupplierCardProps> = ({ supplierName, value, media, productsCount }) => {
  const { urlT } = useUrls()
  const { t } = useTranslation('landing')

  const supplierLogoAltDescription = t('meta:supplierLogoAltDescription', { supplierName })

  return (
    <div data-testid="supplier-card" className={styles['supplier-card']}>
      <Link href={urlT(`/supplier/${value}`)} shallow>
        <Panel bordered bodyFill>
          <FlexboxGrid.Item>
            <FlexboxGrid justify="center" className="padding-top-spacer">
              <Avatar
                size="xl"
                imageSrc={(getSmallPreview(media?.[0]))}
                altName={supplierLogoAltDescription}
              />
            </FlexboxGrid>
            <h5>{supplierName}</h5>
            <p className="margin-top-spacer-half ellipsis">{`${productsCount || 0} ${t('products:product', { count: productsCount })}`}</p>
          </FlexboxGrid.Item>
        </Panel>
      </Link>
    </div>
  )
}
export default memo(SupplierCard)
